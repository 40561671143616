@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* To depreciate fp-dialog and switch to fp-dialog-2
    due to specificity preventing overriding inline */

  .fp-dialog,
  .fp-dialog-2 {
    @apply z-50 bg-black/30;
    @apply fixed inset-0 min-h-full min-w-full;
    @apply overflow-x-hidden overflow-y-hidden;
  }

  .fp-dialog > div,
  .fp-dialog-2 > div {
    @apply fixed inset-0 flex items-center justify-center;
  }

  .fp-dialog > div > div,
  .fp-dialog-2-panel {
    @apply max-h-screen w-full max-w-xl;
    @apply bg-white p-6 text-left align-middle;
    @apply rounded-xl shadow-xl;
    @apply flex h-auto flex-col overflow-auto;
    @apply sm:block;
    @apply mx-8;
  }

  .fp-dialog > div > div.max {
    @apply max-w-6xl;
  }

  .fp-dialog > div > div.extra-wide {
    @apply max-w-4xl;
  }

  .fp-dialog > div > div.wide {
    @apply max-w-2xl;
  }

  .fp-dialog > div > div.narrow {
    @apply max-w-lg;
  }

  .fp-dialog > div > div > h2 {
    @apply text-lg font-bold leading-normal;
  }

  .fp-dialog > div > div > p:first-of-type {
    @apply pt-3 pb-5;
    @apply text-slate-600;
  }
}
