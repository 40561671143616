@import "combobox.css";
@import "dialog.css";
@import "listbox.css";
@import "menu.css";
@import "radio-group.css";
@import "switch.css";
@import "text-input.css";
@import "error-message.css";
@import "top-pick.css";
/*
    ! NOTE - DO NOT EXTEND/BUILD ON COMPONENT STYLING CLASSES
    !        DEPRECATED IN FAVOUR OF COMMON COMPONENT LIBRARY (14 AUG 2024)

    Component styling classes are available for common UI components (like
    buttons) and HeadlessUI (https://headlessui.dev/).

    How to use:

    1)  All styling classes are prefixed with "fp-"
    
    2)  Classes are conveniently named after their name in HeadlessUI, or their
        typical reference name. Their styling are also split and named after
        their class names, so you can easily refer to the list of styles
        available via the /styles/common/components folder.
    
    3)  A list of variant suffixes are also available (eg. "-secondary"). The
        primary style is the default, and thus you can omit "-primary". When
        using a variant class, you do not have to apply the base class.

        Some examples:
            .fp-btn (The "primary" variant of "button" component)
            .fp-btn-outline (The "outline" variant of "button" component)

        The variants that are available are as below:
            Available
                -outline
                -disabled
                -error
            Situational
                -filled (when primary is bg-white)
            Reserved (yet to be implemented)
                -secondary
                -accent

    4)  A component library page will be created in the future for easy
        reference to how each classes look like + example codes, and also for
        easy unit testing.

*/
/* 

    The styles below are for /pages/library/components, and are not front-facing (only available in Dev Server) 
    
*/
.fp-library-component-page {
  @apply grid grid-cols-1 gap-4 p-4 md:grid-cols-2;
}

.fp-library-component-page h2 {
  @apply text-xl font-medium;
}

.fp-library-component-page h3 {
  @apply font-medium;
}

.fp-library-component-container {
  @apply mt-4 grid grid-cols-1 gap-2 space-y-4 rounded-lg
        border-2 border-brand-primary p-4 shadow-lg;
}

.fp-library-component-container > div {
  @apply space-y-2;
}

.fp-library-component-container > div > ul {
  @apply pb-1;
}

.fp-library-component-container > div > ul > li {
  @apply list-inside list-disc pl-4 text-xs;
}
