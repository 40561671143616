@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .fp-menu-button {
    @apply w-full relative text-left flex;
    @apply bg-white;
    @apply px-4 py-3;
    @apply border-2 border-brand-primary rounded-md;
    @apply text-sm;
    @apply focus:outline-brand-primary;
  }
  .fp-menu-button *:first-child {
    @apply grow;
  }
  .fp-menu-items {
    @apply bg-white;
    @apply rounded-md shadow-lg;
    @apply absolute z-50;
  }
  .fp-menu-item {
    @apply w-full block;
    @apply px-4 py-3;
    @apply rounded-md;
  }
  .fp-menu-item-active {
    @apply fp-menu-item;
    @apply bg-brand-primary;
    @apply text-white;
  }

  /* -------------------------------------------------- */

  .fp-menu-button-filled {
    @apply fp-menu-button;
    @apply bg-brand-primary text-white;
  }

  .fp-menu-button-filled *:first-child {
    @apply grow;
  }

  .fp-menu-items-filled {
    @apply fp-menu-items;
  }

  .fp-menu-item-filled {
    @apply fp-menu-item;
  }

  .fp-menu-item-active-filled {
    @apply fp-menu-item-active;
  }
}
