@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .fp-radio-group {
    @apply border border-slate-200 rounded-md;
  }

  .fp-radio-group > legend {
    @apply float-left font-bold border-b border-slate-200 px-4 py-2 w-full;
  }

  .fp-radio-group > div {
    @apply border-b border-slate-200 px-4 py-4 w-full space-x-1;
  }

  .fp-radio-group-2 {
    @apply pl-1;
  }

  .fp-radio-group input[type="radio"],
  .fp-radio-group-2 input[type="radio"] {
    @apply appearance-none rounded-full place-self-center;
    @apply w-2.5 h-2.5 shrink-0 grow-0;
    @apply bg-white border-2 border-white ring-1 ring-black;
  }

  .fp-radio-group input[type="radio"]:checked,
  .fp-radio-group-2 input[type="radio"]:checked {
    @apply bg-brand-primary ring-brand-primary;
  }
}
