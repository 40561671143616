@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .fp-auth-layout {
    @apply w-full px-4;
  }

  .fp-auth-layout > h1 {
    @apply text-center text-4xl font-light text-white;
  }

  .fp-auth-layout > div {
    @apply mx-auto my-6 max-w-xl rounded-3xl bg-white p-10;
    @apply space-y-6;
  }

  .fp-auth-layout > div > h2 {
    @apply text-center text-2xl font-black;
  }

  .fp-auth-layout > div > p {
    @apply text-center font-light tracking-wide text-slate-500;
  }

  .fp-auth-layout > div > form > div label {
    @apply text-sm font-light tracking-wide text-slate-500;
  }

  .fp-auth-layout > p {
    @apply text-center font-light text-white;
  }
}
