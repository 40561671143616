@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .fp-listbox {
    @apply rounded-lg border-2 border-slate-300;
    @apply focus:ring-2 focus:ring-brand-primary focus:ring-offset-1;
    @apply relative w-full justify-center;
    @apply bg-white text-sm text-black;
  }

  .fp-listbox > button {
    @apply relative flex w-full py-3 px-4 text-left;
    @apply cursor-default;
    @apply focus:border-brand-primary focus:outline-brand-primary;
  }

  .fp-listbox > button > p {
    @apply grow;
  }

  .fp-listbox-options {
    @apply absolute z-50 mt-0.5 w-full;
    @apply rounded-md shadow-md;
  }

  .fp-listbox-options > li {
    @apply flex select-none rounded-md py-3 px-4;
    @apply bg-white text-black;
    @apply hover:bg-brand-primary hover:text-white;
  }

  .fp-listbox-options > li.active {
    @apply bg-brand-primary text-white;
  }

  .fp-listbox-options > li > span {
    @apply grow;
  }
}
