@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .fp-combobox {
    @apply relative w-full justify-center;
    @apply bg-white text-sm text-black;
    @apply focus:outline-none;
  }

  .fp-combobox > input[type="text"] {
    @apply rounded-lg border-2 border-slate-300;
    @apply focus:border-brand-primary;
    @apply relative w-full justify-center;
    @apply bg-white text-sm text-black;
    @apply py-3 px-4;
    @apply focus:outline-none;
  }

  .fp-combobox > button > p {
    @apply grow;
  }

  .fp-combobox-options {
    @apply absolute z-50 mt-1 w-full;
    @apply rounded-md bg-white shadow-lg;
  }

  .fp-combobox-options > li {
    @apply flex select-none rounded-md py-3 px-4;
    @apply bg-white text-black;
  }

  .fp-combobox-options > li > span {
    @apply grow;
  }

  .fp-combobox-options > .fp-active {
    @apply bg-slate-300/50;
  }

  .fp-combobox-options > .fp-inactive > svg {
    @apply text-brand-primary;
  }
}
