@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .fp-text-input {
    @apply border-2 border-slate-300 rounded-lg;
    @apply focus:border-brand-primary;
    @apply w-full relative justify-center;
    @apply bg-white text-black text-sm;
    @apply py-3 px-4;
    @apply focus:outline-none;
  }

  .fp-text-deal {
    @apply border-2 border-slate-300 rounded-lg;
    @apply w-full relative justify-center;
    @apply bg-white text-black text-sm;
    @apply py-3 px-4;
    @apply focus:outline-none;
  }

  .fp-text-input-personal-info {
    @apply border-2 border-slate-300 rounded-[4px];
    @apply w-full relative justify-center;
    @apply bg-white text-black text-sm;
    @apply py-3 px-4;
    @apply focus:outline-none;
  }
}
