.error-message {
    @apply pt-1.5;
}

.error-message > h1,
.error-message > h2,
.error-message > h3,
.error-message > h4,
.error-message > h5,
.error-message > h6,
.error-message > p,
.error-message > ul > li,
.error-message > ol > li {
    @apply text-sm text-red-600 font-normal;
    @apply leading-tight;
}

.error-message > ul > li,
.error-message > ol > li {
    @apply list-disc list-outside ml-5;
}