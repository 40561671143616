@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .fp-switch-enabled,
  .fp-switch-disabled {
    @apply w-full flex;
  }

  .fp-switch-rail {
    @apply focus:ring-brand-primary;
    @apply relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .fp-switch-knob {
    @apply bg-white;
    @apply inline-block h-4 w-4 transform rounded-full transition-transform;
  }

  .fp-switch-enabled > label,
  .fp-switch-disabled > label {
    @apply grow text-sm;
  }

  .fp-switch-enabled > button {
    @apply fp-switch-rail bg-brand-primary;
  }

  .fp-switch-enabled > button > span {
    @apply fp-switch-knob translate-x-6;
  }

  .fp-switch-disabled > button {
    @apply fp-switch-rail bg-slate-200;
  }

  .fp-switch-disabled > button > span {
    @apply fp-switch-knob translate-x-1;
  }

  /* -------------------------------------------------- */
}
