@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer components {

  .mainTitle {
      @apply pt-2 text-center text-sm font-normal text-[#68737D];
  }    
  .rowMonthPick {
      @apply flex flex-row items-center no-scrollbar;
      @apply overflow-x-hidden md:overflow-auto;
      @apply mt-4 md:mt-2 gap-x-8 md:gap-x-8;
  }
}
