@import "components/components.css";
@import "layouts/layouts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply appearance-none;
  }

  /*
     * Hide scrollbar while allowing view to be scrolled
     * https://github.com/tailwindlabs/tailwindcss/discussions/2394
     * https://github.com/tailwindlabs/tailwindcss/pull/5732
    */
  .overflow-scrollbar-hide::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
  .overflow-scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Inter, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Set the font weight to bold (originally bolder) for strong tags that we cannot style directly e.g. render from markdown */
strong {
  font-weight: bold;
}


.grecaptcha-badge {
  bottom: 20px !important;
}
