@import "auth.css";

@layer base {
  h1 {
    @apply text-4xl font-black;
  }
  h2 {
    @apply text-3xl font-black;
  }
  h3 {
    @apply text-2xl font-black;
  }
  h4 {
    @apply text-xl font-bold;
  }
  h5 {
    @apply text-lg font-bold;
  }
  h6 {
    @apply font-bold;
  }
  p,
  span {
    @apply font-light;
  }
  .markdown {
    @apply space-y-4 break-words;
  }
  .markdown h1 {
    @apply text-2xl font-black;
  }
  .markdown h2 {
    @apply text-xl font-bold;
  }
  .markdown h3 {
    @apply text-lg font-bold;
  }
  .markdown h4 {
    @apply font-bold;
  }
  .markdown ul > li {
    @apply list-disc ml-6;
  }
  .markdown ol {
    list-style: decimal;
    margin-left: 24px;
  }
  .markdown table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0;
  }
  .markdown td,
  .markdown th {
    border: 1px solid hsl(207, 11.1%, 85.9%);
    height: 30px;
    text-align: left;
    padding: 5px;
    font-weight: normal;
  }
  .markdown a {
    @apply text-brand-primary hover:text-brand-primary-hover;
    text-decoration: underline;
  }
}

@layer components {
  .wrapper {
    @apply w-full lg:max-w-7xl lg:mx-auto px-12;
  }
}
